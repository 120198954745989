import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='AboutUs'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.2, once: true}}             
            >
            <div className={styles.container}>
                    <motion.img className={styles.image} src={image} alt='люди' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>О компании <span>МедКонсалт</span></h2>
                        <p className={styles.text}>&#8195;Сегодня консалтинг медицинских центров и медицинских учреждений становится все более востребованной услугой. Связано это по большей мере с тем, что любой руководитель, в первую очередь, смотрит в сторону увеличения потока клиентов, а не бумажную волокиту, которая не только занимает много времени, но и не приносит фактической прибыли.</p>
                        <p className={styles.text}>&#8195;Зависимость качества услуг и уровня обслуживания от наличия и характеристик медицинской аппаратуры очевидна. Все это влияет на количество клиентов, успех и прибыльность медицинского центра. Необходимо помнить, что аппаратура, включая арендованную, должна соответствовать самым современным требованиям.</p>
                        <p className={styles.text}>&#8195;В начале своей деятельности многие медицинские центры не могут сразу приобрести необходимое оборудование. Это может стать причиной отказа в получении лицензии и ограничить перечень предоставляемых услуг, негативно сказываясь на финансовых показателях организации.</p>
                        <p className={styles.text}>&#8195;Наша компания предлагает услуги аренды медицинской техники и помощь в получении медицинских лицензий. Наши специалисты помогут определить необходимое оборудование для оснащения кабинетов врачей.</p>
                        <p className={styles.text}>&#8195;Сегодня медицинские центры и учреждения все чаще обращаются за консультациями. Прежде всего, руководителям важно привлечение большего числа клиентов, а не занятие бумажной работой, которая только отнимает время и не приносит реальной прибыли.</p>
                        <p className={styles.text}>&#8195;10 лет успешной работы в сфере медицинского консалтинга принесли нам множество довольных клиентов и постоянных заказчиков. Компания "МедКонсалт" стала хорошо известна. Мы любим нашу работу и считаем, что в этом заключается наш секрет успеха.</p>
                        <p className={styles.text}>&#8195;Мы — команда профессионалов, для которых нет невыполнимых задач. Мы всегда трепетно относимся ко всему, что делаем, и делаем все возможное для выполнения поставленных задач. Постоянное развитие, слаженная и оперативная работа нашей команды, работа с клиентами — это то, чем мы по праву можем гордиться.</p>
                    </motion.div>
            </div>
        </motion.section>
        
        
    )
}