import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"
import { motion } from "framer-motion"
import styles from './CardiologistOffice.module.scss'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import image from '../../image/CardiologistOffice/img_1.webp'
import image_1 from '../../image/CardiologistOffice/img_2.webp'
import image_2 from '../../image/CardiologistOffice/img_3.webp'
import image_3 from '../../image/CardiologistOffice/img_4.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const CardiologistOffice = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <Helmet>
            <title>Стандарт оснащения кардиолога, оборудование для лицензирования кардиологического кабинета</title>
            <meta name="description" content="Стандарт оснащения кардиологического кабинета. Нужное оборудование для лицензирования, нюансы и порядок действий. Подробную информацию вы найдете на странице."></meta>
            <meta property="og:title" content="Стандарт оснащения кардиолога, оборудование для лицензирования кардиологического кабинета"/>
            <meta property="og:description" content="Стандарт оснащения кардиологического кабинета. Нужное оборудование для лицензирования, нюансы и порядок действий. Подробную информацию вы найдете на странице."/>
        </Helmet>
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'Кабинет кардиолога'}
            >
                <p className={styles.menu_text}><span>&ensp;/&ensp;</span></p>
                <Link className={styles.menu_link} to='/blog'>Блог</Link>
            </BreadCrumbs>
            <SliderTechnologies/>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}} 
            >
                    <motion.img className={styles.image} src={image} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h1 className={styles.title}>Онащение <span>кардиологического кабинета</span></h1>
                        <p className={styles.text}>&#8195;Сердечно-сосудистые заболевания находятся на первом месте как причина смертности среди всех патологий и болезней, обогнав даже онкологические новообразования. Больше трети всех смертельных случаев в мире связаны с ССЗ. Пандемия COVID-19 только увеличила этот показатель. Однако число смертей от ССЗ превысило показатель смертности от короновируса в 17 раз.</p>
                        <p className={styles.text}>Боязнь посещения поликлиники, перенос планового оперативного вмешательства, жизнь в режиме постоянных переживаний и стресса, смена профиля многих ЛПУ в «инфекционные короновирусные госпитали», абсолютное отсутствие плановой квалифицированной помощи во время самоизоляции — все это стало причиной таких печальных последствий. Еще длительное время врачи-кардиологи будут обнаруживать осложнения и решать большое количество проблем, которые стали результатами короновирусной эпидемии.</p>
                        <h2 className={styles.subtitle}>Нормативная база</h2>
                        <p className={styles.text}>&#8195;Для получения лицензии, разрешающей проведение медицинской деятельности, кардиологу нужно подготовить определенный пакет документов и обустроить кабинет в соответствии со стандартными требованиями. Придется соблюсти большое число разнообразных стандартов, которые регламентируют основные параметры помещений, необходимых коммуникаций, кондиционирования, оборудования, мебели и приборов, которые прошли сертификацию.</p>
                        <p className={styles.text}>Базовым документом, который регламентирует стандарт оснащения кабинета кардиолога, считается приложение №3 к приказу №918н от 15.11.2012. Именно здесь содержится полный перечень необходимого оборудования, предметов мебели и интерьера, приборов и инструментов, которые позволят получить лицензию.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_1} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Требования к <span>кабинету&#160;кардиолога</span></h2>
                        <p className={styles.text}>&#8195;Кардиология остается сферой медицины, отличающейся повышенной ответственностью. Это связано с тем, что заболевания и патологии органов ССС остаются в числе лидеров, провоцирующих смертность населения даже при оказании квалифицированной помощи. Поэтому российское законодательство выдвигает достаточно строгие требования к работе кардиологического кабинета. Следование установленным нормам станет основным условием, которое позволит получить лицензию.</p>
                        <p className={styles.text}>Кабинет врача-кардиолога должен отвечать требованиям, касающимся расположения, освещения, наличия источников искусственного света, планировки основного и вспомогательных помещений, необходимой площади, вентиляции и кондиционирования. Проведение всех работ должно соответствовать нормам СЭС, правилам противопожарной безопасности.</p>
                        <h2 className={styles.subtitle}>Нюансы расположения</h2>
                        <p className={styles.text}>&#8195;Кабинет кардиолога можно расположить в отдельно стоящем здании или в многоквартирном. Однако обязательным условием является наличие отдельного входа, что позволит больным не пересекаться и не встречаться с местными жителями. Чтобы привлечь внимание потенциальных пациентов, можно повесить специальную вывеску.</p>
                        <p className={styles.text}>В случае дополнительного открытия процедурной, отделения функциональной диагностики, операционной при кардиологическом кабинете желательно разместить такие помещения в отдельно стоящем здании. Также можно арендовать необходимую площадь в государственном ЛПУ.</p>
                        <h2 className={styles.subtitle}>Обязательные коммуникации</h2>
                        <p className={styles.text}>&#8195;Кабинет кардиолога оснащается санузлом, должна быть подведена холодная и горячая вода (при отсутствии централизованного водоснабжения и на период проведения ремонтных работ устанавливается водонагреватель). Также проводится приточно-вытяжная вентиляция и система кондиционирования.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_2} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.subtitle}>Площадь помещений</h2>
                        <p className={styles.text}>&#8195;Кабинет, предназначенный для приема и консультирования пациентов, должен иметь площадь минимум 12 м2. Если планируется оснащение операционного блока и процедурной, минимальный размер помещения составляет 18 м2. Допустимая высота потолка — не меньше 2,60 метров.</p>
                        <p className={styles.text}>Установлены нормы также для помещения, в котором пациенты будут ожидать приема. Так, принято считать необходимую площадь с учетом 1 м2 на 1 пациента. Рекомендуется заранее определить примерный поток больных.</p>
                        <h2 className={styles.subtitle}>Внутренняя отделка</h2>
                        <p className={styles.text}>&#8195;Согласно требованиям к оформлению и оснащению кабинета врача-кардиолога для лицензирования в 2023 году, все материалы для отделки можно подбирать индивидуально, однако они должны соответствовать определенным стандартам. В частности:</p>
                        <p className={styles.text}>&#8195;1. Облицовка стен осуществляется панелями, глазированной или поливинилхлоридной плиткой. Использование керамической плитки запрещено из-за повышенного риска образования трещин и сколов, что приводит к микробной контаминации. Для подобных помещений лучше подходят светлые бежевые тона, успокаивающе воздействующие на пациентов. Допустимым считается вариант оштукатуривания стен с последующей покраской. В случае аренды помещения в многоквартирном доме обязательной является отделка внутренних поверхностей шумоизолирующим материалом.</p>
                        <p className={styles.text}>&#8195;2. Для потолков подходят подвесные, подшивные, натяжные конструкции.</p>
                        <p className={styles.text}>&#8195;3. Напольное покрытие обязательно с антискользящим эффектом. Это поможет предотвратить возможное травмирование пациентов, поскольку на прием часто приходят пожилые люди. В качестве такового можно использовать линолеум в темных тонах, ПВХ-плитку, керамогранитное покрытие с антискользящим эффектом. Поверхность должна быть гладкой, чтобы предотвратить скапливание патогенной микрофлоры.</p>
                        <p className={styles.text}>На приеме у кардиолога пациенту должно быть спокойно и максимально комфортно. По мере необходимости нужно обновлять ремонт. При этом запрещено использовать продукцию, имеющую специфический запах.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_3} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.subtitle}>Освещение</h2>
                        <p className={styles.text}>&#8195;В кабинете должны быть источники естественного и искусственного освещения. Рекомендуется размещать рабочий стол врача таким образом, чтобы солнечный свет падал с левой стороны. Оконные проемы декорируются посредством жалюзи, рулонных штор. Это позволит закрывать окна во время обследования больного.</p>
                        <p className={styles.text}>Минимальные нормы освещенности:</p>
                        <p className={styles.text} ><span>&#10003;</span> основное пространство — 150 лк;</p>
                        <p className={styles.text} ><span>&#10003;</span> рабочее место — от 500 до 1000 лк;</p>
                        <p className={styles.text} ><span>&#10003;</span>  комната ожидания — от 150 до 200;</p>
                        <p className={styles.text} ><span>&#10003;</span>   туалет — 100;</p>
                        <p className={styles.text} ><span>&#10003;</span>   операционный блок — от 20000 до 40000 лк.</p>
                        <p className={styles.text}>Искусственные источники света оснащаются светодиодными лампами со цветовой температурой от 4200 до 4500 К. Подойдут также люминесцентные, однако они отличаются более высокой энергозатратностью и меньшим сроком работы.</p>
                        <h2 className={styles.subtitle}>Микроклимат помещения</h2>
                        <p className={styles.text}>&#8195;Микроклиматические условия влияют на комфорт пациента и работоспособность персонала. Согласно нормативам оснащения кабинета кардиологии, в помещении обязательна приточно-вытяжная вентиляция. Рекомендуется установить систему кондиционирования. Это позволит охлаждать воздух летом и подогревать зимой. Оптимальной температурой в кардиологическом кабинете считается показатель в 18-20 градусов, влажность воздуха — от 40 до 60%.</p>
                        <p className={styles.text}>Кабинет следует проветривать регулярно, 2 раза за смену нужна влажная уборка, во время которой используются мыльные растворы и дезсредства. Раз в 7 дней проводится генеральная уборка.</p>
                        <p className={styles.text}>Обеззараживание воздуха осуществляется посредством бактерицидного облучателя. Предпочтение следует отдавать передвижным моделям, которые удобно перемещать в нужное помещение.</p>
                        <h2 className={styles.subtitle}>Оборудование для оснащения кардиологии</h2>
                        <p className={styles.text}>&#8195;Все медицинское оборудование следует выбирать, опираясь на требования и установленные стандарты. При этом кардиологический кабинет необходимо оснастить прогрессивными приборами и устройствами, в том числе должны быть ПК, МФУ, аппарат УЗИ с набором разнообразных датчиков.</p>
                        <p className={styles.text}>Согласно стандарту оснащения кардиологии, в кабинете специалиста должны быть:</p>
                        <p className={styles.text} ><span>&#10003;</span> весы напольные. Они используются для измерения массы тела больного, поскольку лишний вес может стать причиной прогрессирования заболеваний ССС.</p>
                        <p className={styles.text} ><span>&#10003;</span> Ростомер для определения соотношения между массой тела и ростом больного.</p>
                        <p className={styles.text} ><span>&#10003;</span> Лента сантиметра для измерения окружности талии, бедер, что помогает определить локализацию жировой ткани.</p>
                        <p className={styles.text} ><span>&#10003;</span> Термометр для измерения температуры тела. Гипертермия может спровоцировать ухудшение работы сердца.</p>
                        <p className={styles.text} ><span>&#10003;</span> Секундомер, чтобы измерить пульс.</p>
                        <p className={styles.text} ><span>&#10003;</span> Негатоскоп для изучения рентген-снимков и томограммы.</p>
                        <p className={styles.text} ><span>&#10003;</span> Кардиоскоп для отслеживания динамики работы сердечной мышцы.</p>
                        <p className={styles.text} ><span>&#10003;</span> Механический тонометр для измерения АД.</p>
                        <p className={styles.text} ><span>&#10003;</span> Электрокардиограф. Позволяет измерить сердечный ритм и ЧС миокарда.</p>
                        <p className={styles.text} ><span>&#10003;</span> Фонендоскоп для проведения первичного осмотра, помогает выявить шум в сердце и нарушения сердцебиения.</p>
                        <p className={styles.text} ><span>&#10003;</span> Экспресс-анализатор кардиомаркеров, портативная модель.</p>
                        <p className={styles.text} ><span>&#10003;</span> Аппарат для определения МНО, портативная модель.</p>
                        <p className={styles.text} >Помимо этого, в кабинете устанавливают 2 эргономичных стола, 2 стула, кресло или стул для пациентов, шкаф для хранения лекарственных средств и документации, ширму, вешалку для одежды, кушетку.</p>
                        <p className={styles.text} >Чтобы получить лицензию, оснащение и мебель кардиологического кабинета должны строго соответствовать требования нормативных актов.</p>
                    </motion.div>
            </motion.div>
            <SliderTechnologies/>
        </motion.section>
        </>
    )
}