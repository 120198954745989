import React from 'react'
import styles from './Preloader.module.scss'
import you from '../../image/Header/logo.png'

export const Preloader = ({isPreloader}) => {


    return (
            <section className={styles.main} >
                <div className={styles.container}>
                    <img className={styles.image}  src={you} alt='фотография'/>
                    <h2 className={styles.title}>МедКонсалт</h2>
                </div>
            </section>
    )
}