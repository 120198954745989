import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"
import { motion } from "framer-motion"
import styles from './FunctionalDiagnostics.module.scss'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import image from '../../image/FunctionalDiagnostics/img_1.webp'
import image_1 from '../../image/FunctionalDiagnostics/img_2.webp'
import image_2 from '../../image/FunctionalDiagnostics/img_3.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const FunctionalDiagnostics = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <Helmet>
            <title>Стандарт онащения кабинета функциональной диагностики, оборудование для лицензирования</title>
            <meta name="description" content="Стандарт оснащения кабинета функциональной диагностики. Нужное оборудование для лицензирования, нюансы и порядок действий. Подробную информацию вы найдете на странице."></meta>
            <meta property="og:title" content="Стандарт онащения кабинета функциональной диагностики, оборудование для лицензирования"/>
            <meta property="og:description" content="Стандарт оснащения кабинета функциональной диагностики. Нужное оборудование для лицензирования, нюансы и порядок действий. Подробную информацию вы найдете на странице."/>
        </Helmet>
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'Функциональная диагностика'}
            >
                <p className={styles.menu_text}><span>&ensp;/&ensp;</span></p>
                <Link className={styles.menu_link} to='/blog'>Блог</Link>
            </BreadCrumbs>
            <SliderTechnologies/> 
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}} 
            >
                    <motion.img className={styles.image} src={image} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h1 className={styles.title}>Онащение кабинета <span>функциональной диагностики</span></h1>
                        <p className={styles.text}>&#8195;Функциональная диагностика представляет собой совокупность мероприятий и инструментальных методик, помогающих своевременно обнаружить нарушения в функциях органов, систем живого организма. В рамках обследования изучается работа сердечной мышцы, магистральных и периферических сосудов, легких, ЦНС.</p>
                        <p className={styles.text}>В государственных и частных ЛПУ, поликлиниках оборудуются помещения для проведения функциональной диагностики. Здесь принимают больных, которые проходят соответствующее обследование или отслеживают эффективность назначенной терапии. Оснащение подобных помещений должно полностью соответствовать принятым стандартам.</p>
                        <h2 className={styles.subtitle}>Нормативные акты</h2>
                        <p className={styles.text}>&#8195;Оснащение кабинета функциональной диагностики осуществляется согласно стандартам, утвержденным МЗО. Они тщательно расписаны в нормативных актах. В России функционирование кабинета подчиняется приказу №283 от 30.11.1993, приложению №11 к приказу №572н от 01.11.2012.</p>
                        <h2 className={styles.subtitle}>Основные требования к помещению</h2>
                        <p className={styles.text}>&#8195;Кабинет для проведения функциональной диагностики допустимо оборудовать не только на базе государственного медицинского учреждения, но и в частных центрах. Однако у любого ЛПУ должна быть лицензия, подтверждающая право предоставления медицинских услуг.</p>
                        <p className={styles.text}>Такое помещение должно отвечать определенным требованиям, которые затрагивают его расположение, оснащение, монтирование источников искусственного освещения, наличия и планировки основных и вспомогательных кабинетов, необходимой площади, проведение вентиляции и установки системы кондиционирования и обогрева (при необходимости).</p>
                        <p className={styles.text}>Все проведенные работы должны точно соответствовать требованиям СЭС, не нарушать правила противопожарной безопасности.</p>
                        <h2 className={styles.subtitle}>Нормы расположения</h2>
                        <p className={styles.text}>&#8195;Под обустройство кабинета диагностики можно выделить несколько помещений, в зависимости от потребностей ЛПУ. Расположение его должно быть таким, чтобы была возможность изолировать посетителей друг от друга в случае наступления неблагоприятной эпидобстановки.</p>
                        <p className={styles.text}>В проекте помещения учитываются разнообразные факторы, которые могут оказать влияние на бесперебойную работы приборов, устройств и медицинской аппаратуры. К примеру, мощное излучение от силовых кабелей, рентгеновских аппаратов, электрощитовой искажают показатели некоторых устройств. Поэтому кабинет диагностики рекомендуется располагать минимум за 10 м от рентген-кабинета и за 150 м — от физиотерапевтического.</p>
                        <p className={styles.text}>Согласно стандарту оснащения функциональной диагностики для лицензирования в 2023, кабинет запрещено размещать в подвале, на цокольном этаже. Для размещения диагностических помещений следует выбрать свободную площадь на основном этаже. В обратном случае получить лицензию не получится.</p>
                        <h2 className={styles.subtitle}>Требуемая площадь</h2>
                        <p className={styles.text}>&#8195;Определение площади помещения проводится на основании данных о размещении оснащения, оборудования, мебели, числа работников. Если в кабинете будет работать 2 сотрудника, его размер должна быть не меньше 20 м2.</p>
                        <p className={styles.text}>Чаще всего под отделение функциональной диагностики отводится 3 комнаты. В первой устанавливают габаритное оборудование. Здесь проводятся основные диагностические мероприятия. Во второй будет размещаться кабинет врача. Площадь такого помещения — не менее 12 м2. В третьей комнате устанавливают вешалки для одежды, ширмы. Здесь пациенты будут переодеваться перед обследованием. Раздевалка, как правило, небольшая и занимает около 4 м2.</p>
                        <h2 className={styles.subtitle}>Отделка помещений и меблировка</h2>
                        <p className={styles.text}>&#8195;По стандарту оснащения отделения функциональной диагностики высота потолка не может быть ниже 3 м. Для покрытия стен, разделительных перегородок, ширм должны использоваться материалы, создающие гладкое покрытие. К тому же подходят отделочные материалы, которые обладают влагостойкостью, износостойкостью, устойчивостью к воздействию дезрастворов. В кабинете необходимо регулярно проводить влажную уборку, дезинфекционные мероприятия для предупреждения микробной контаминации. Запрещено стены оклеивать обоями, поскольку в таком случае провести уборку, соответствующую требованиям СЭС, невозможно.</p>
                        <p className={styles.text}>Для облицовки стен подходит керамическая плитка, которую можно выкладывать на высоту не больше 1,6 м от поверхности пола.</p>
                        <p className={styles.text}>Цветовая гамма внутренней отделки должна основываться на белых или светлых оттенках. Это поможет успокоить пациентов во время проведения диагностики.</p>
                        <p className={styles.text}>В отделке внутренних поверхностей не допустимо наличие любых дефектов, поскольку в них будет скапливаться влага, пыль, патогенные микроорганизмы.</p>
                        <p className={styles.text}>В помещении диагностики следует регулярно проводить влажную уборку, дезинфекцию, стерилизацию.</p>
                        <p className={styles.text}>Для оборудования диагностического кабинета следует отдать предпочтение функциональной эргономичной мебели, изготовленной из безопасных материалов. Все предметы интерьера должны отличаться повышенной устойчивостью к действию влаги, агрессивных дезсредств. Кушетка, предназначенная для первичного осмотра пациентов, и диван в комнате ожидания должны иметь обивку из искусственной кожи, которую можно обработать антисептиком.</p>
                        <p className={styles.text}>В кабинете, предназначенном для проведения функциональной диагностики, должны быть следующие предметы мебели:</p>                        
                        <p className={styles.text} ><span>&#10003;</span> 2 рабочих стола (для врача и медсестры);</p>                       
                        <p className={styles.text} ><span>&#10003;</span> диван, стулья для пациентов;</p>                      
                        <p className={styles.text} ><span>&#10003;</span> кушетка для осмотра;</p>                      
                        <p className={styles.text} ><span>&#10003;</span> тумбы для размещения приборов;</p>                        
                        <p className={styles.text} ><span>&#10003;</span> шкафчики, этажерки для хранения документации, расходных материалов;</p>                        
                        <p className={styles.text} ><span>&#10003;</span> вешалки;</p>                       
                        <p className={styles.text} ><span>&#10003;</span> полка для обуви (для персонала);</p>                       
                        <p className={styles.text} ><span>&#10003;</span> столик для пеленания.</p>                     
                        <p className={styles.text}>Для зонирования помещения используются специальные перегородки, передвижные ширмы. Для обеззараживания воздуха в помещении устанавливается бактерицидная лампа.</p>
                        <p className={styles.text}>В кабинете обязательно размещается раковина для обработки рук.</p>
                        <p className={styles.text}>Всю мебель и аппаратуру размещают таким образом, чтобы медперсоналу и посетителям было удобно перемещаться. Как правило, мебель устанавливают вдоль стены.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_1} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.subtitle}>Освещение</h2>
                        <p className={styles.text}>&#8195;Обязательны источники естественного (окна) и искусственного (светильники) освещения. Оконные проемы должны обеспечивать достаточное количество дневного света. Исключение составляет комната для проведения МРТ.</p>
                        <p className={styles.text}>В диагностическом кабинете предусматривается общее, местное освещение. В качестве общего используют люминесцентные лампы, а местного — лампочки накаливания. В итоге уровень освещенности помещения должен быть не меньше 150 лк.</p>
                        <h2 className={styles.subtitle}>Условия микроклимата</h2>
                        <p className={styles.text}>&#8195;В диагностических помещениях обязательно монтируется система кондиционирования, приточно-вытяжная вентиляция. Температура — в пределах 20-22 градусов, поэтому при необходимости устанавливают радиаторы отопления с гладким покрытием, влажность воздуха — от 40 до 60%.</p>
                        <p className={styles.text}>Допустимый уровень шума не может превышать 40 дБ. Для этого кабинет оборудуется шумоизоляционной системой.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_2} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.subtitle}>Стандартный перечень оборудования</h2>
                        <p className={styles.text}>&#8195;Порядок оснащения кабинета функциональной диагностики предполагает установку аппаратуры и приборов, которые соответствуют стандартам МЗО.</p>
                        <p className={styles.text}>В кабинете, как правило, размещаются аппараты для исследования работы органов ССС, ЦНС и органов дыхания. В помещении находятся:</p>
                        <p className={styles.text}><span>&#10003;</span> Электрокардиограф, который применяется для проверки работы сердечной мышцы. С его помощью отслеживаются электрические потенциалы, что позволит оценить ритм и показатель проводимости.</p>
                        <p className={styles.text}><span>&#10003;</span> Эхокардиограф. Используется для своевременного обнаружения функциональных и морфологических патологий сердца.</p>
                        <p className={styles.text}><span>&#10003;</span> Спирометр для обследования органов дыхания. Предназначен для замера объема вдыхаемого и выдыхаемого воздуха, помогает определить возможную причину одышки.</p>
                        <p className={styles.text}><span>&#10003;</span> Электроэнцефалограф для отслеживания функциональной мозговой активности. Он фиксирует электрические потенциалы, с помощью которых определяются пораженные области, активность коры и другие показатели.</p>
                        <p className={styles.text}><span>&#10003;</span> Тонометр для измерения АД в магистральных сосудах.</p>
                        <p className={styles.text}><span>&#10003;</span> Аппарат для суточного мониторинга АД.</p>
                        <p className={styles.text}><span>&#10003;</span> Аппарат для холтеровского мониторинга работы сердца. Используется для непрерывной регистрации сердечных сокращений в течение суток. Позволяет обнаружить самые незначительные отклонения от нормы.</p>
                        <p className={styles.text}>Перечисленные виды оборудования считаются основными, которые должны обязательно размещаться в диагностическом кабинете. При необходимости перечень можно дополнять.</p>
                        <p className={styles.text}>Оборудование для оснащения кабинета не обязательно сразу же покупать, его можно арендовать, чтобы быстрее получить необходимую лицензию.</p>
                    </motion.div>
            </motion.div>
            <SliderTechnologies/>
        </motion.section>
        </>
    )
}