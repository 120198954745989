import React, {useEffect} from 'react'
import styles from './Main.module.scss'
import { motion } from "framer-motion"
import { InitialPage } from '../../components/InitialPage/InitialPage'
import {AboutUs} from '../../components/AboutUs/AboutUs'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import { Callback } from '../../components/Callback/Callback'
import { Advantages } from '../../components/Advantages/Advantages'
import { OurServices } from '../../components/OurServices/OurServices'
import check from '../../image/Licensing/check.svg'
import { Preloader } from '../../components/Preloader/Preloader'
import { Device } from '../../components/Device/Device'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5}
    } 
}



export const Main = ({isOpen, popupOpen, isPreloader}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className={styles.main}>
            <Preloader
                isPreloader={isPreloader}
            />
            <InitialPage
                isOpen={isOpen}
            />
            <SliderTechnologies/>
            <AboutUs/>
            <OurServices/>
            <Device
                isOpen={isOpen}
            />
            <Advantages/>
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Востребованность<span> услуги</span></motion.h2>
                <motion.div className={styles.item} variants={boxAnimation}>
                    <p className={styles.text}>&#8195;Прокат медицинского оборудования – удобный и эффективный способ обеспечения лечебных учреждений, клиник или частных кабинетов нужной техникой без необходимости приобретения его на постоянной основе.</p>
                    <h3 className={styles.subtitle}>Эта услуга может быть полезна для широкого круга людей и организаций, включая:</h3>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Медицинские учреждения: больницы, клиники, поликлиники, лаборатории. Аренда медоборудования позволяет им оперативно обновлять и расширять свою техническую базу с учетом изменяющихся требований и потребностей;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Медицинские специалисты: врачи, стоматологи, физиотерапевты и другие. Аренда позволяет им пользоваться высококачественным оборудованием без больших финансовых затрат на его приобретение;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Реабилитационные и спортивные центры: аренда медицинского оборудования позволяет им обеспечить своих клиентов современным и эффективным оборудованием для проведения различных процедур и тренировок;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Частные лица: аренда медицинского оборудования может быть полезна для отдельных людей, нуждающихся во временном использовании медицинских устройств в домашних условиях, например, после операции.</p>
                        </div>
                        <p className={styles.text}>&#8195;Таким образом, услуга будет полезна как юридическим, так и физическим лицам, тем или иным образом связанным с медицинской деятельностью.</p>
                </motion.div>
            </motion.div>
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Основные плюсы<span> аренды</span></motion.h2>
                <motion.div className={styles.item} variants={boxAnimation}>
                    <p className={styles.text}>&#8195;Аренда медицинского оборудования для лицензирования обеспечивает медицинским организациям доступ к современному оборудованию без значительных финансовых затрат. Это отличное решение как для частных кабинетов, так и для крупных клиник, которые только начинают свою деятельность или расширяют свой спектр услуг. С помощью услуги медицинские учреждения могут сэкономить средства и получить доступ к самым новым технологиям в области здравоохранения.</p>
                    <h3 className={styles.subtitle}>Услуга пользуется большим спросом, что объясняется следующими преимуществами проката медицинского оборудования:</h3>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Экономия средств: при аренде оборудования нет необходимости в больших первоначальных инвестициях, а платежи обычно включают в себя все необходимое обслуживание и ремонт;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Гибкость: аренда позволяет клиентам выбирать нужное оборудование с учетом конкретных потребностей, а также легко менять его в случае необходимости;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Постоянное обновление оборудования: арендодатели тщательно следят за актуальностью и исправностью предоставляемого ими ассортимента, что позволяет арендующим организациям и лицам пользоваться самыми современными и эффективными устройствами.</p>
                        </div>
                        <h3 className={styles.subtitle}>Помимо перечисленного, стоит отметить:</h3>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Доступную цену на предоставляемую услугу;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Собственный склад в Москве;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Возможность взять в аренду медтехнику в любом регионе страны;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Круглосуточный доступ к консультации по телефону и посредством онлайн сервисов;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Доступ к аренде дорогостоящих аппаратов, редко встречающейся технике;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Отсутствие посредников.</p>
                        </div>
                </motion.div>
            </motion.div>
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Основные плюсы<span> аренды</span></motion.h2>
                <motion.div className={styles.item} variants={boxAnimation}>
                    <p className={styles.text}>&#8195;Важно помнить, что условия аренды могут варьироваться в зависимости от наименования техники, поэтому рекомендуется тщательно изучить все условия и задать нам вопросы, прежде чем принять окончательное решение.</p>
                    <h3 className={styles.subtitle}>Чтобы купить медтехнику для лицензирования напрокат по доступной цене, достаточно придерживаться наших рекомендаций:</h3>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Определите потребности медицинского учреждения: рассмотрите свои конкретные требования и сферу деятельности, чтобы выбрать нужное оборудование;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Сделайте запрос или свяжитесь с нами. Вы можете заполните форму обратной связи на сайте или позвонить нам по телефону, чтобы обсудить условия аренды;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Подпишите договор: совместно с нашими сотрудниками вы можете оформить письменный договор аренды, уточняющий спецификацию оборудования, сроки аренды, стоимость и другие важные условия. При оформлении заявки с доставкой в другие города, возможно оформление договора дистанционно;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Получите и оплатите оборудование: после подписания договора мы предоставим вам необходимое оборудование, проведем установку и настройку, и вы сможете начать его использование.</p>
                        </div>
                </motion.div>
            </motion.div>
            <Callback
                popupOpen={popupOpen}
            />
        </section>
    )
}