import React from 'react'
import { Link } from 'react-router-dom'
import styles from './OurServices.module.scss'
import { motion } from "framer-motion"
import image_2 from '../../image/Licensing/img_1.webp'
import image_3 from '../../image/SanitaryHygienic/img.webp'
import image_4 from '../../image/InformationSystem/img_1.webp'
import image_5 from '../../image/Rent/img_1.webp'

export const OurServices = () => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .3 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .6}
        } 
    }

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Наши<span> услуги</span></motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.image} src={image_5} alt='медицина'/>
                            </div>
                            <div className={styles.box_info}>
                                <h3 className={styles.subtitle}>Прокат оборудования</h3>
                                <Link className={styles.button} to="/prokat-oborudovaniya">подробнее</Link>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.image} src={image_2} alt='медицина'/>
                            </div>
                            <div className={styles.box_info}>
                                <h3 className={styles.subtitle}>Лицензирование</h3>
                                <Link className={styles.button} to="/licenzirovanie-deyatelnosti" >подробнее</Link>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.image} src={image_3} alt='медицина'/>
                            </div>
                            <div className={styles.box_info}>
                                <h3 className={styles.subtitle}>Получение СЭЗ</h3>
                                <Link className={styles.button} to="/sanitarno-epidemiologicheskoe-zaklyuchenie" >подробнее</Link>
                            </div>
                            
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.image} src={image_4} alt='медицина'/>
                            </div>
                            <div className={styles.box_info}>
                                <h3 className={styles.subtitle}>Подключение к ЕГИСЗ</h3>
                                <Link className={styles.button} to="/edinaya-gosudarstvennaya-informacionnaya-sistema">подробнее</Link>
                            </div>
                        </li>
                    </motion.ul>
            </div>   
        </motion.section>
        
        
    )
}