import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './InformationSystem.module.scss'
import { Callback } from '../../components/Callback/Callback'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import image from '../../image/InformationSystem/img_1.webp'
import image_1 from '../../image/InformationSystem/img_2.webp'
import image_2 from '../../image/InformationSystem/img_3.webp'
import check from '../../image/Licensing/check.svg'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { Advantages } from '../../components/Advantages/Advantages'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5}
    } 
}

export const InformationSystem = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'ЕГИСЗ'}
            />
            <SliderAdvantages/>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}} 
            >
                    <motion.img className={styles.image} src={image} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h1 className={styles.title}>Подключение к <span> ЕГИСЗ</span></h1>
                        <p className={styles.text}>&#8195;Единая государственная информационная система здравоохранения (ЕГИСЗ) – это современная платформа, предоставляющая доступ к электронным медицинским данным жителей России. Подключение к ЕГИСЗ является обязательным для всех медицинских организаций, в том числе и для частных учреждений, которые оказывают платные медицинские услуги.</p>
                        <p className={styles.text}>&#8195;Подключение к ЕГИСЗ для частных медицинских организаций является важным шагом для повышения эффективности и качества медицинского обслуживания. Процедура требует определенных знаний, что затрудняет самостоятельное подключение. Мы готовы оказать содействие в проведении процедуры, что позволит за приемлемую цену сэкономить время на сбор документов и составление заявлений.</p>
                    </motion.div>
            </motion.div>
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Наши <span> услуги</span></motion.h2>
                <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_2} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Регистрация в ЕГИСЗ</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_1} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Внесение сведений в ФРМО и ФРМР</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                </motion.ul>
            </motion.div>
            <Advantages/> 
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Плюсы <span>процедуры</span></motion.h2>
                <motion.div className={styles.item} variants={boxAnimation}>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Упрощение ведения медицинской документации. ЕГИСЗ позволяет организовать единый электронный медицинский архив пациента, что значительно упрощает ведение документации и доступ к необходимым данным;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Ускорение процесса предоставления медицинской помощи. Возможность быстрого доступа к медицинским данным позволяет сократить временные затраты на поиск необходимой информации, что обеспечивает более эффективное и быстрое оказание медицинской помощи;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Улучшение качества медицинского обслуживания. Подключение к ЕГИСЗ позволяет эффективно управлять медицинскими ресурсами и обеспечивать более высокое качество медицинского обслуживания.;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Увеличение доверия пациентов. Это стимулирует доверие пациентов, так как подразумевает обработку и хранение их медицинских данных с соблюдением всех требований и норм безопасности;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Улучшение взаимодействия с другими медицинскими организациями. Подключение к ЕГИСЗ создает возможность для частных медицинских организаций взаимодействовать с государственными и другими частными учреждениями, обмениваться информацией и координировать работу.</p>
                        </div>
                </motion.div>
            </motion.div>
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Этапы оказания <span>услуги</span></motion.h2>
                <motion.div className={styles.item} variants={boxAnimation}>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Зарегистрироваться на официальном сайте ЕГИСЗ. На сайте системы нужно создать учетную запись и зарегистрировать медицинскую организацию;</p>
                    </div>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Подготовить необходимые документы. Медицинская организация должна предоставить все необходимые документы, такие как лицензия на оказание медицинской деятельности, соглашение о сотрудничестве с ТФОМС и другими медицинскими организациями, а также документацию по персоналу;</p>
                    </div>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Пройти технические требования. Медицинская организация должна оснастить свои системы соответствующим программным обеспечением и оборудованием, чтобы обеспечить передачу информации в ЕГИСЗ;</p>
                    </div>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Пройти обучение и сертификацию. Персонал медицинской организации должен пройти обязательное обучение и сертификацию для работы в ЕГИСЗ;</p>
                    </div>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Подписать соглашение с контролирующим органом. Медицинская организация должна заключить соглашение с Федеральной службой по надзору в сфере здравоохранения или другим уполномоченным органом.</p>
                    </div>
                    <p className={styles.text}>&#8195;Подключение к ЕГИСЗ для частных медицинских организаций является важным шагом для повышения эффективности и качества медицинского обслуживания. Процедура требует определенных знаний, что затрудняет самостоятельное подключение. Мы готовы оказать содействие в проведении процедуры, что позволит за приемлемую цену сэкономить время на сбор документов и составление заявлений.</p>
                </motion.div>
            </motion.div>
            <SliderTechnologies/>
            <Callback
                popupOpen={popupOpen}
            />
        </motion.section>
    )
}