import React from 'react'
import styles from './Device.module.scss'
import image_1 from '../../image/Rent/image_1.webp'
import image_2 from '../../image/Rent/image_2.webp'
import image_3 from '../../image/Rent/image_3.webp'
import image_4 from '../../image/Rent/image_4.webp'
import image_5 from '../../image/Rent/image_5.webp'
import image_6 from '../../image/Rent/image_6.webp'
import image_7 from '../../image/Rent/image_7.webp'
import image_8 from '../../image/Rent/image_8.webp'
import image_9 from '../../image/Rent/image_9.webp'
import image_10 from '../../image/Rent/image_10.webp'
import image_11 from '../../image/Rent/image_11.jpg'
import image_12 from '../../image/Rent/image_12.jpg'
import image_13 from '../../image/Rent/image_13.jpg'
import image_14 from '../../image/Rent/image_14.jpg'
import image_15 from '../../image/Rent/image_15.jpg'
import image_16 from '../../image/Rent/image_16.jpg'
import image_17 from '../../image/Rent/image_17.png'
import image_18 from '../../image/Rent/image_18.webp'
import image_19 from '../../image/Rent/image_19.jpg'
import image_20 from '../../image/Rent/image_20.png'
import image_21 from '../../image/Rent/image_21.jpg'
import image_22 from '../../image/Rent/image_22.webp'
import image_23 from '../../image/Rent/image_23.jpg'
import image_24 from '../../image/Rent/image_24.jpg'
import image_25 from '../../image/Rent/image_25.jpg'
import image_26 from '../../image/Rent/image_26.jpg'
import image_27 from '../../image/Rent/image_27.webp'
import image_28 from '../../image/Rent/image_28.webp'
import image_29 from '../../image/Rent/image_29.jpg'
import image_30 from '../../image/Rent/image_30.jpg'
import image_31 from '../../image/Rent/image_31.jpg'
import image_32 from '../../image/Rent/image_32.webp'
import image_33 from '../../image/Rent/image_33.jpg'
import image_34 from '../../image/Rent/image_34.jpg'
import image_35 from '../../image/Rent/image_35.jpg'
import image_36 from '../../image/Rent/image_36.jpg'



export const Device = ({isOpen, popupOpen}) => {


    return (
            <div className={styles.box_page}>
                <h2 className={styles.title_centre}>Мы предлагаем <span> оборудование</span></h2>
                <ul className={styles.list}>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_1} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Анестезиология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_2} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Офтальмология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_3} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Оториноларингология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_4} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Урология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_5} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Гинекология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_6} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Колопроктология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_7} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Профпатология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_8} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Неврология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_9} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Детская неврология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_10} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Педиатрия</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_11} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Кардиология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_12} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Косметология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_13} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Дерматовенерология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_14} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Эндокринология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_15} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Детская эндокринология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_16} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Хирургия</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_17} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Детская хирургия</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_18} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Травматология и ортопедия</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_19} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Рефлексотерапия</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_20} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Функциональная диагностика</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_21} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Массаж</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_22} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Остеопат</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_23} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Стоматология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_24} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>УЗИ</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_25} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Процедурный кабинет</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_26} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Пульмонология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_27} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Аллерголог-иммунолог</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_28} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Терапевт</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_29} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Онколог</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_30} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Гастроэнтерология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_31} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Диетология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_32} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Предрейсовые осмотры</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_33} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Психиатр-нарколог</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_34} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Травматология (операционный блок)</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_35} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Лабораторное оборудование</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_36} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Детская кардиология</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                </ul>
            </div>
    )
}