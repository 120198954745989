import React from 'react'
import styles from './InitialPage.module.scss'
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"

export const InitialPage = ({isOpen}) => {

    return (
        <section 
        className={styles.main} 
        id='initial'
        >
            <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    effect={"fade"}
                    autoHeight={true}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[EffectFade, Pagination, Autoplay,]}
                    className="mySwiper"
            >
                <SwiperSlide>
                    <div className={styles.item}>
                        <div className={styles.overlay}>
                            <div className={styles.box}>
                                <h1 className={styles.title}>Прокат медицинского оборудования</h1>
                                <p className={styles.subtitle} >Мы всего лишь на расстоянии телефонного звонка</p>
                                <button className={styles.button} type='button' onClick={isOpen}>оставить заявку</button>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
                <SwiperSlide>
                    <div className={styles.item_one}>
                        <div className={styles.overlay}>
                            <div className={styles.box}>
                                <h2 className={styles.title_one}>Лицензирование медицинской деятельности</h2>
                                <p className={styles.subtitle}>Мы всего лишь на расстоянии телефонного звонка</p>
                                <button className={styles.button} type='button' onClick={isOpen}>оставить заявку</button>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
                <SwiperSlide>
                    <div className={styles.item_three}>
                        <div className={styles.overlay}>
                            <div className={styles.box}>
                                <h2 className={styles.title_one}>Получение СЭЗ на&#160;медицинскую деятельность</h2>
                                <p className={styles.subtitle} >Мы всего лишь на расстоянии телефонного звонка</p>
                                <button className={styles.button} type='button' onClick={isOpen}>оставить заявку</button>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
                <SwiperSlide>
                    <div className={styles.item_two}>
                        <div className={styles.overlay}>
                            <div className={styles.box}>
                                <h2 className={styles.title}>Подключение к ЕГИСЗ</h2>
                                <p className={styles.subtitle}>Мы всего лишь на расстоянии телефонного звонка</p>
                                <button className={styles.button} type='button' onClick={isOpen}>оставить заявку</button>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
            </Swiper>      
        </section>
    )
}