import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './Company.module.scss'
import { Callback } from '../../components/Callback/Callback'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import { Advantages } from '../../components/Advantages/Advantages'
import { Device } from '../../components/Device/Device'
import image from '../../image/Rent/img_1.webp'
import check from '../../image/Licensing/check.svg'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5}
    } 
}


export const Company = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'Прокат оборудования'}
            />
            <SliderAdvantages/>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h1 className={styles.title}>Прокат медицинского оборудования<span> для лицензирования</span></h1>
                        <p className={styles.text}>&#8195;Многие медицинские учреждения, особенно те, которые только планируют получение лицензии или расширяют свою деятельность, сталкиваются с проблемой приобретения необходимого медицинского оборудования. Покупка оборудования может быть очень затратной и требует значительных финансовых вложений, которые не всегда доступны для молодых и малых медицинских организаций. В таких случаях прокат медицинского оборудования для лицензирования становится отличным решением.</p>
                        <p className={styles.text}>&#8195;Наша компания предлагает услуги аренды медицинской техники и помощь в получении медицинских лицензий. Наши специалисты помогут определить необходимое оборудование для оснащения кабинетов врачей.</p>
                        <p className={styles.text}>&#8195;10 лет успешной работы в сфере медицинского консалтинга принесли нам множество довольных клиентов и постоянных заказчиков. Компания "МедКонсалт" стала хорошо известна. Мы любим нашу работу и считаем, что в этом заключается наш секрет успеха.</p>
                    </motion.div>
            </motion.div>
            <Device
                isOpen={isOpen}
            />
            <Advantages/> 
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Главные плюсы <span> услуги</span></motion.h2>
                <motion.div className={styles.item} variants={boxAnimation}>
                    <p className={styles.text}>&#8195;Аренда медицинского оборудования для лицензирования обеспечивает медицинским организациям доступ к современному оборудованию без значительных финансовых затрат. Это отличное решение как для частных кабинетов, так и для крупных клиник, которые только начинают свою деятельность или расширяют свой спектр услуг. С помощью услуги медицинские учреждения могут сэкономить средства и получить доступ к самым новым технологиям в области здравоохранения.</p>
                    <h3 className={styles.subtitle}>Преимущества проката медицинского оборудования для лицензирования:</h3>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Экономия денег. Вместо значительных капиталовложений на покупку дорогостоящего оборудования, аренда медтехники позволяет сэкономить деньги на оплате аренды. Это особенно актуально для малых и средних медицинских организаций, у которых ограничен бюджет;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Гибкость. Прокат медицинского оборудования дает возможность выбирать необходимое оборудование на конкретный период времени и в нужном объеме. В случае изменения потребностей или необходимости обновления оборудования, можно легко менять арендованное оборудование.;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Быстрый доступ к новым технологиям. Медицинская техника постоянно совершенствуется и обновляется. Прокат позволяет быть в курсе последних технологических разработок и использовать самое современное оборудование без необходимости долгосрочных финансовых вложений;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Техническая поддержка. В случае возникновения проблем с арендованным оборудованием, осуществляющая его прокат компания предоставляет техническую поддержку и ремонт. Это позволяет медицинским учреждениям экономить время и усилия на поиск и ремонт неисправностей.;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Гарантия качества. Компании, специализирующиеся на прокате медицинского оборудования, строго следят за его техническим состоянием. Оборудование проходит регулярную проверку и обслуживание, что гарантирует его надежность и соответствие требованиям.</p>
                        </div>
                </motion.div>
            </motion.div>
            <SliderTechnologies/>
            <Callback
                popupOpen={popupOpen}
            />
        </motion.section>
    )
}