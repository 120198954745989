import React, { useState}  from 'react'
import styles from './ButtonCommunication.module.scss'


export const ButtonCommunication = ({isOpen}) => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        } 
        else if (scrolled <= 300){
            setVisible(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <button className={styles.button}  type='button' onClick={isOpen} style={{display: visible ? 'flex' : 'none'}} aria-label='быстрые контакты'>
            <span className={styles.pulse}></span>
            <span className={styles.pulse_two}></span>
        </button>
    )
}