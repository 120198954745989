import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './Licensing.module.scss'
import { Callback } from '../../components/Callback/Callback'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import image from '../../image/Licensing/img_1.jpg'
import image_1 from '../../image/Licensing/image_3.webp'
import image_2 from '../../image/Licensing/image_2.webp'
import image_3 from '../../image/Licensing/image_4.webp'
import image_4 from '../../image/Licensing/image_5.webp'
import check from '../../image/Licensing/check.svg'
import { Advantages } from '../../components/Advantages/Advantages'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5}
    } 
}

export const Licensing = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'Лицензирование'}
            />
            <SliderAdvantages/>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}} 
            >
                    <motion.img className={styles.image} src={image} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h1 className={styles.title}>Лицензирование <span> медицинской деятельности</span></h1>
                        <p className={styles.text}>&#8195;Согласно федеральному закону, медицинские организации, будь то крупные медицинские центры, клиники или кабинеты врачей частной практики, обязательно должны иметь лицензию на осуществление медицинской деятельности в выбранных областях. Подготовка к получению медицинской лицензии требует значительных усилий, времени и финансовых затрат. Одним из важных этапов этого процесса является правильное оснащение медицинских кабинетов, соответствующее стандартам России.</p>
                        <p className={styles.text}>&#8195;Лицензия на осуществление медицинской деятельности действует бессрочно. Мы поможем облегчить процесс лицензирования и прохождение всех возможных проверок контролирующих органов. Наша компания предлагаем услуги по аренде оборудования для лицензирования. Именно грамотное оснащение учреждения существенно упростит получение документов и позволит без длительной подготовки и вложений сразу начать медицинскую практику.</p>
                        <p className={styles.text}>&#8195;Мы предлагаем оборудование для успешного лицензирования кабинетов различных специалистов, таких как кардиолог, офтальмолог, уролог, гинеколог, невролог, отоларинголог, колопроктолог, психиатр-нарколог и врачей других направлений медицины. В нашем ассортименте есть оборудование для функциональной и УЗ- диагностики, массажа, медосмотров и процедурных кабинетов. Мы также поможем оснастить кабинеты детских специалистов.</p>
                        <p className={styles.text}>&#8195;Опытные консультанты нашей компании готовы помочь вам с получением лицензии на осуществление медицинской деятельности и подобрать необходимое оснащение для медицинских кабинетов. У нас вы можете арендовать как отдельные единицы оборудования, так и полный комплект с соответствующей документацией, включая технические паспорта и регистрационные удостоверения. Арендованное оборудование отличается высоким качеством, проверено на исправность и сертифицировано. Оно готово к использованию при работе с пациентами.</p>
                    </motion.div>
            </motion.div>
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Виды <span> лицензирования</span></motion.h2>
                <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_1} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Лицензирование медицинской деятельности</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_2} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Лицензирование фармацевтической деятельности</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_3} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Лицензирование на образовательноую деятельность</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                        <li className={styles.cell}>
                            <div className={styles.cell_box}>
                                <img className={styles.cell_image} src={image_4} alt='больница'/>
                            </div>
                            <div className={styles.cell_info}>
                                <h3 className={styles.cell_subtitle}>Лицензирование деятельности по обороту наркотических средств</h3>
                                <button className={styles.cell_button} type='button' onClick={isOpen}>РАССЧИТАТЬ СТОИМОСТЬ</button>
                            </div>
                        </li>
                </motion.ul>
            </motion.div>
            <Advantages/> 
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Наши<span> плюсы</span></motion.h2>
                <motion.div className={styles.item} variants={boxAnimation}>
                    <p className={styles.text}>&#8195;Работа с каждым клиентом осуществляется индивидуально, учитывая имеющиеся запросы и потребности. Мы стремимся создать комфортные условия для плодотворного сотрудничества, поэтому наша работа всегда ориентирована на ваше удобство. Именно поэтому мы предлагаем полный пакет услуг, а не просто предоставляем технику.</p>
                    <p className={styles.text}>&#8195;Обращаясь к нам, вы можете быть уверенным, что получите то, что искали. Наш главный принцип - максимальная доступность и постоянная связь с клиентами. Мы готовы стать надежным партнером как для владельцев частных кабинетов, так и для представителей крупных медицинских центров.</p>
                    <h3 className={styles.subtitle}>Нашими плюсами являются:</h3>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Наличие собственного склада со всем необходимым оборудованием;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Круглосуточная поддержка наших клиентов;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Доступные цены на прокат оборудования;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Консультирование как в рамках выбора техники для лицензирования медицинской деятельности, так и при оформлении заказа;</p>
                        </div>
                        <div className={styles.item_box}>
                            <img className={styles.check} src={check} alt='галочка'/>
                            <p className={styles.item_text} >Доставка любого типа оборудования по всей стране с соблюдением правил транспортировки ценных грузов.</p>
                        </div>
                </motion.div>
            </motion.div>
            <motion.div
                className={styles.box_page}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
            >
                <motion.h2 className={styles.title_centre} variants={titleAnimation}>Как <span> оформить заявку?</span></motion.h2>
                <motion.div className={styles.item} variants={boxAnimation}>
                    <p className={styles.text}>&#8195;При необходимости пройти лицензирование медицинской деятельности нет необходимости вкладывать финансы во все оборудование, которые требуется законодательно. Для получения документов на разрешение деятельности достаточно воспользоваться арендой.</p>
                    <h3 className={styles.subtitle}>Этапы оформления заявки:</h3>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Свяжитесь с наши по телефону или посредством онлайн-формы;</p>
                    </div>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Укажите, какой вид деятельности вы осуществляете;</p>
                    </div>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Определитесь, какое оборудование требуется, нужен ли полный комплект или достаточного конкретного наименования;</p>
                    </div>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Заключите договор, указав сроки и условия аренды;</p>
                    </div>
                    <div className={styles.item_box}>
                        <img className={styles.check} src={check} alt='галочка'/>
                        <p className={styles.item_text} >Получите оборудование.</p>
                    </div>
                    <p className={styles.text}>&#8195;Благодаря прокату медицинской техники вы можете без труда пройти лицензирование, независимо от направления деятельности и финансовых возможностей.</p>
                </motion.div>
            </motion.div>
            <SliderTechnologies/>
            <Callback
                popupOpen={popupOpen}
            />
        </motion.section>
    )
}