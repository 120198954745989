import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './Blog.module.scss'
import { Callback } from '../../components/Callback/Callback'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import image_1 from '../../image/CardiologistOffice/img_1.webp'
import image_2 from '../../image/OphthalmologOffice/img_1.webp'
import image_3 from '../../image/UrologicalOffice/img_1.webp'
import image_4 from '../../image/FunctionalDiagnostics/img_1.webp'
import image_5 from '../../image/ClinicalDiagnosticLaboratory/img_1.webp'
import image_6 from '../../image/StamologicalLaboratory/img_1.webp'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5}
    } 
}

const Blog = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'Блог'}
            />
            <SliderAdvantages/>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}} 
            >
                <motion.h1 className={styles.title} variants={titleAnimation}>Интересные <span>статьи</span></motion.h1>
                <motion.div className={styles.line} variants={titleAnimation}></motion.div>
                <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <img className={styles.image} src={image_6} alt='больница'/>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>Онащение <span>стоматологического кабинета</span></h3>
                            <p className={styles.item_tixt}>При открытии стоматологического кабинета каждый понимает, что его ждет большая конкуренция, а потому придется приложить много усилий. Ведь согласно ФЗ «О лицензировании отдельных видов деятельности» №99 от 2011 года, медицинские услуги можно оказывать только после оформления соответствующей лицензии. Получить ее может юридическое лицо или физическое (понадобится статус ИП). У лицензии нет ограниченного срока действия, переоформлять ее нужно только при смене юридического адреса учреждения, места проживания соискателя, изменения/дополнения списка предоставляемых стоматологических услуг.
</p>
                        </div>
                        <Link className={styles.item_link} to='/kabinet-stomatologii'>ПОДРОБНЕЕ...</Link>
                    </li>
                    <li className={styles.item}>
                        <img className={styles.image} src={image_1} alt='больница'/>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>Онащение <span>кардиологического кабинета</span></h3>
                            <p className={styles.item_tixt}>Для получения лицензии, разрешающей проведение медицинской деятельности, кардиологу нужно подготовить определенный пакет документов и обустроить кабинет в соответствии со стандартными требованиями. Придется соблюсти большое число разнообразных стандартов, которые регламентируют основные параметры помещений, необходимых коммуникаций, кондиционирования, оборудования, мебели и приборов, которые прошли сертификацию.</p>
                        </div>
                        <Link className={styles.item_link} to='/kabinet-kardiologii'>ПОДРОБНЕЕ...</Link>
                    </li>
                    <li className={styles.item}>
                        <img className={styles.image} src={image_2} alt='больница'/>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>Оснащение <span>кабинета офтальмолога</span></h3>
                            <p className={styles.item_tixt}>Оснащение отделения офтальмологии предусматривает естественное освещение и размещение источников искусственного. Рекомендуется размещать помещения для офтальмологов в северной части здания. Тогда естественного освещения будет достаточно для оказания квалифицированной помощи в любое время года. Если окна помещения выходят на юг, их следует обеспечить специальными средствами защиты, включая козырьки и жалюзи.</p>
                        </div>
                        <Link className={styles.item_link} to='/kabinet-oftalmologa'>ПОДРОБНЕЕ...</Link>
                    </li>
                    <li className={styles.item}>
                        <img className={styles.image} src={image_3} alt='больница'/>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>Оснащение <span>урологического кабинета</span></h3>
                            <p className={styles.item_tixt}>Урологическое отделение (кабинет) является структурной единицей медицинской организации. Его создание требует соблюдения определенных норм и правил. Наличие нужного оснащения, которое полностью соответствует существующим современным стандартам, правильное естественное освещение, дополненное источниками искусственного света, расположение и площадь кабинета обеспечивают эффективную работу специалиста.</p>
                        </div>
                        <Link className={styles.item_link} to='/kabinet-urologa'>ПОДРОБНЕЕ...</Link>
                    </li>
                    <li className={styles.item}>
                        <img className={styles.image} src={image_4} alt='больница'/>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>Кабинет  <span>функциональной диагностики</span></h3>
                            <p className={styles.item_tixt}>По стандарту оснащения отделения функциональной диагностики высота потолка не может быть ниже 3 м. Для покрытия стен, разделительных перегородок, ширм должны использоваться материалы, создающие гладкое покрытие. К тому же подходят отделочные материалы, которые обладают влагостойкостью, износостойкостью, устойчивостью к воздействию дезрастворов. В кабинете необходимо регулярно проводить влажную уборку, дезинфекционные мероприятия</p>
                        </div>
                        <Link className={styles.item_link} to='/kabinet-funkcionalnoy-diagnostiki'>ПОДРОБНЕЕ...</Link>
                    </li>
                    <li className={styles.item}>
                        <img className={styles.image} src={image_5} alt='больница'/>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>Клинико-диагностическая лаборатория</h3>
                            <p className={styles.item_tixt}>Клинико-диагностическая лаборатория (КДЛ) занимает центральное место в системе здравоохранения, выполняя критически важную роль в диагностике, мониторинге и предотвращении заболеваний. Эффективность и точность диагностических процедур напрямую зависят от качества используемого оборудования, его соответствия современным стандартам и требованиям.</p>
                        </div>
                        <Link className={styles.item_link} to='/kliniko-diagnosticheskaya-laboratoriya'>ПОДРОБНЕЕ...</Link>
                    </li>
                </motion.ul>
            </motion.div>           
            <SliderTechnologies/>
            <Callback
                popupOpen={popupOpen}
            />
        </motion.section>
    )
}

export default Blog