import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"
import { motion } from "framer-motion"
import styles from './StamologicalLaboratory.module.scss'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import image from '../../image/StamologicalLaboratory/img_1.webp'
import image_1 from '../../image/StamologicalLaboratory/img_2.webp'
import image_2 from '../../image/StamologicalLaboratory/img_3.webp'
import image_3 from '../../image/CardiologistOffice/img_4.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const StamologicalLaboratory = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <Helmet>
            <title>Стандарт оснащения стоматолога, оборудование для лицензирования стамотологического кабинета</title>
            <meta name="description" content="Стандарт оснащения стамотологического кабинета. Нужное оборудование для лицензирования, нюансы и порядок действий. Подробную информацию вы найдете на странице."></meta>
            <meta property="og:title" content="Стандарт оснащения стоматолога, оборудование для лицензирования стамотологического кабинета"/>
            <meta property="og:description" content="Стандарт оснащения стамотологического кабинета. Нужное оборудование для лицензирования, нюансы и порядок действий. Подробную информацию вы найдете на странице."/>
        </Helmet>
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'Кабинет стоматолога'}
            >
                <p className={styles.menu_text}><span>&ensp;/&ensp;</span></p>
                <Link className={styles.menu_link} to='/blog'>Блог</Link>
            </BreadCrumbs>
            <SliderTechnologies/>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}} 
            >
                    <motion.img className={styles.image} src={image} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h1 className={styles.title}>Онащение <span>кабинета стоматолога</span></h1>
                        <p className={styles.text}>&#8195;При открытии стоматологического кабинета каждый понимает, что его ждет большая конкуренция, а потому придется приложить много усилий. Ведь согласно ФЗ «О лицензировании отдельных видов деятельности» №99 от 2011 года, медицинские услуги можно оказывать только после оформления соответствующей лицензии. Получить ее может юридическое лицо или физическое (понадобится статус ИП). У лицензии нет ограниченного срока действия, переоформлять ее нужно только при смене юридического адреса учреждения, места проживания соискателя, изменения/дополнения списка предоставляемых стоматологических услуг.</p>
                        <p className={styles.text}>Для осуществления частной практики стоматологу следует получить лицензию на такие виды деятельности:</p>
                        <p className={styles.text} ><span>&#10003;</span> терапевтическая стоматология, предполагающая проведение необходимых лечебных мероприятий для устранения кариеса, пародонтоза и других заболеваний, без применения хирургических методик;</p>
                        <p className={styles.text} ><span>&#10003;</span> хирургическая: все виды оперативных манипуляций;</p>
                        <p className={styles.text} ><span>&#10003;</span> ортопедическая предназначена для проведения процедур зубной имплантации и протезирования;</p>
                        <p className={styles.text} ><span>&#10003;</span> ортодонтальная включает коррекцию зубного ряда с помощью использования пластинок, брекет-систем;</p>
                        <p className={styles.text} ><span>&#10003;</span> детская объединяет разнообразные методики, включая хирургические, которые используются для лечения зубов у детей;</p>
                        <p className={styles.text} ><span>&#10003;</span> для проведения рентген-диагностики необходимо специальное оборудование и получение специальной лицензии.</p>
                        <p className={styles.text}>Специфические требования касаются помещения, где планируется разместить стоматологический кабинет, его ремонта, оснащения, мебели, персонала. Значительно сэкономить и облегчить процесс получения лицензии позволит аренда стоматологического оборудования. Часто такое решение является единственным возможным шансом для представителей малого бизнеса, имеющих ограниченные финансовые возможности в начале своего пути.</p>
                        <p className={styles.text}>Аренда медоборудования в компании «МедКонсалт» поможет решить сразу 2 основные задачи: даст возможность легко и в максимально короткие сроки получить разрешительный документ для осуществления стоматологической деятельности, а также поможет сэкономить финансы, которые впоследствии можно направить на развитие медицинского центра.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_1} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Требования к <span>кабинету&#160;стоматолога</span></h2>
                        <p className={styles.text}>&#8195;Лицензионные требования для открытия частного или кабинета в государственном медцентре абсолютно одинаковы. Как правило, стандарты оснащения затрагивают:</p>
                        <p className={styles.text} ><span>Расположение.</span> Помещение, предназначенное для предоставления медицинских услуг, следует размещать в районах, где фиксируются нормальные показатели ПДК опасных и сильнодействующих соединений.</p>
                        <p className={styles.text} ><span>Площадь.</span> Размер помещения должен быть таким, чтобы персонал и пациенты чувствовали себя здесь вполне комфортно и не мешали друг другу, а мебель и оборудование можно было расставить максимально эргономично.</p>
                        <p className={styles.text} ><span>Освещение.</span> Соотношение уровня естественного и искусственного света должны соответствовать требованиям СЭС.</p>
                        <p className={styles.text} ><span>Внутреннюю отделку.</span> Необходимо использовать только безопасные отделочные материалы, которые имеют высокие показатели износостойкости.</p>
                        <p className={styles.text} ><span>Коммуникации.</span> Проведение вентиляционных систем, отопления, кондиционирования, водоснабжения следует проводить в соответствии со строительными, противопожарными нормами.</p>
                        <p className={styles.text} ><span>Санузлы.</span> Гардеробные, туалетные помещения и комнаты отдыха обустраивают отдельно для клиентов и персонала.</p>
                        <p className={styles.text} ><span>Мебель.</span> У всех предметов интерьера должна быть гладкая поверхность.</p>
                        <p className={styles.text} ><span>Оборудование.</span> При необходимости оборудование, аппараты и другое оснащение можно взять напрокат. Перечь предоставляемого оснащения есть на сайте компании и полностью соответствует лицензионным требованиям.</p>
                        <h2 className={styles.subtitle}>Нюансы расположения</h2>
                        <p className={styles.text}>&#8195;Стоматологический кабинет рекомендуется размещать в нежилом помещении с отдельным входом. Законность владения помещением можно подтвердить договором аренды или правом собственности.</p>
                        <p className={styles.text}>В случае дополнительного открытия процедурной, отделения функциональной диагностики, операционной при кардиологическом кабинете желательно разместить такие помещения в отдельно стоящем здании. Также можно арендовать необходимую площадь в государственном ЛПУ.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_2} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.subtitle}>Допустимая площадь</h2>
                        <p className={styles.text}>&#8195;Минимально разрешенная площадь стоматологического кабинета составляет 14 м2. Высота потолка — от 2,6 м. Комната для отдыха персонала — 6 м2. Размер холла необходимо рассчитать таким образом, чтобы на одного посетителя приходилось примерно 1,2 м2.</p>
                        <h2 className={styles.subtitle}>Внутренняя отделка</h2>
                        <p className={styles.text}>&#8195;Все поверхности рекомендуется оформлять в светлых оттенках. Основной характеристикой отделочных материалов считается устойчивость к влиянию агрессивных моющих средств, которые используются во время проведения уборки и дезинфекции.</p>
                        <p className={styles.text}>Материалы для осуществления ремонта должны соответствовать противопожарным и санитарно-гигиеническим требованиям. Стены могут быть оштукатурены или окрашены, выложены плиткой. Поверхности должны быть гладкими, не иметь швов и сколов, выполняться из влагостойких материалов.</p>
                        <p className={styles.text}>Соблюдение указанных норм позволит достичь необходимого класса чистоты помещений.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_3} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.subtitle}>Освещение</h2>
                        <p className={styles.text}>&#8195;В стоматологическом кабинете устанавливаются потолочные лампы, светильник. Все осветительные устройства оснащаются сплошным рассеивателем.</p>
                        <h2 className={styles.subtitle}>Микроклимат помещения</h2>
                        <p className={styles.text}>&#8195;Нормальные условия микроклимата достигаются путем установки систем вентиляции, кондиционирования, отопления. Подводимые коммуникации лучше скрыть, сохранив при этом доступ для проведения ремонтных работ.</p>
                        <p className={styles.text}>Температура воздуха в кабинете должна быть не выше 25 градусов, значение влажности — не более 55%.</p>
                        <h2 className={styles.subtitle}>Стоматологическое оборудование</h2>
                        <p className={styles.text}>&#8195;Оснащение медицинского кабинета предполагает установку оборудования и аппаратуры, которые должны иметь соответствующую лицензию. При отсутствии необходимого можно оформить прокат оборудования для стоматологии. Предлагаемые приборы, стоматологические кресла и другие устройства представлены выше. Они полностью соответствуют нормативным лицензионным требованиям.</p>
                        <p className={styles.text}>Доступная цена аренды оборудования позволяет установить стоимость стоматологических услуг на уровне, который будет привлекательным для новых клиентов. Таким образом, учреждение станет конкурентоспособным. При этом будет оснащено современным оборудованием, которое имеет высокую производительность, позволяя предоставлять полный перечень услуг.</p>
                        <p className={styles.text}>Все стоматологическое оборудование и необходимая техника компании «МедКонсалт» регулярно подвергается комплексной проверке, проходя при необходимости качественное обслуживание.</p>
                        <p className={styles.text}>Во время заключения договора каждый клиент получает комплект необходимой документации, что позволит легко его эксплуатировать.</p>
                    </motion.div>
            </motion.div>
            <SliderTechnologies/>
        </motion.section>
        </>
    )
}