import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './Feedback.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { Callback } from '../../components/Callback/Callback'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { arrFeedback } from '../../utils/data'
import { CardImage } from '../../components/CardImage/CardImage'


const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5}
    } 
}

export const Feedback = ({isImageOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'Отзывы'}
            />
            <SliderAdvantages/>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}} 
            >
                <motion.h1 className={styles.title_centre} variants={titleAnimation}>Отзывы от <span> наших заказчиков</span></motion.h1>
                <motion.ul className={styles.list} variants={boxAnimation}>
                    {arrFeedback.map((item, index) =>{
                            const keyUid = uuidv4()
                                return(
                                    <CardImage
                                        key={keyUid}
                                        item={item}
                                        index={index}
                                        isImageOpen={isImageOpen}
                                        arr={arrFeedback} 
                                    />
                                ) 
                        })}
                </motion.ul>
            </motion.div>
            <SliderTechnologies/>
            <Callback
                popupOpen={popupOpen}
            />
        </motion.section>
    )
}