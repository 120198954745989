import icon_1 from '../image/MyServices/img_1.jpg'
import icon_2 from '../image/MyServices/img_2.jpg'
import icon_3 from '../image/MyServices/img_3.jpg'
import icon_4 from '../image/MyServices/img_4.jpg'
import icon_7 from '../image/MyServices/img_7.jpg'
import icon_8 from '../image/MyServices/img_8.jpg'
import icon_9 from '../image/MyServices/img_9.jpg'
import icon_6 from '../image/MyServices/img_6.jpg'
import icon_5 from '../image/MyServices/img_5.jpg'
import icon_10 from '../image/MyServices/img_10.jpg'
import icon_11 from '../image/MyServices/img_11.jpg'
import icon_12 from '../image/MyServices/img_12.jpg'

import fid_1 from '../image/Feedback/image_1.webp'
import fid_2 from '../image/Feedback/image_2.webp'
import fid_3 from '../image/Feedback/image_3.webp'
import fid_4 from '../image/Feedback/image_4.webp'
import fid_5 from '../image/Feedback/image_5.webp'
import fid_6 from '../image/Feedback/image_6.webp'
import fid_7 from '../image/Feedback/image_7.webp'
import fid_8 from '../image/Feedback/image_8.webp'

export const arrAdvantages = [{text:'ВЫЕЗД специалиста на осмотр'}, {text:'Официальный ДОГОВОР'}, {text:'Собственное ОБОРУДОВАНИЕ'}, {text:'ДОСТАВКА по всей РФ'}, {text:'Удобная система ОПЛАТЫ'}, {text:'Работаем под КЛЮЧ'},];

export const arrTechnologies =[{image: icon_5}, {image: icon_6}, {image: icon_10}, {image: icon_1}, {image: icon_2}, {image: icon_3}, {image: icon_4}, {image: icon_7}, {image: icon_8}, {image: icon_9},  {image: icon_11}, {image: icon_12},]

export const arrFeedback =[{image: fid_1}, {image: fid_2}, {image: fid_3}, {image: fid_4}, {image: fid_5}, {image: fid_6}, {image: fid_7}, {image: fid_8},]