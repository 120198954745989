import React, { useState, useCallback, useEffect} from 'react'
import {Helmet} from "react-helmet"
import { Route, Routes} from 'react-router-dom'
import app from './App.module.scss'
import { AppHeader } from '../AppHeader/AppHeader'
import { Main } from '../../pages/Main/Main'
import { ModalForm } from '../ModalForm/ModalForm'
import { Modal } from '../Modal/Modal'
import { UpButton } from '../UpButton/UpButton'
import { ButtonCommunication } from '../ButtonCommunication/ButtonCommunication'
import { MenuMobile } from '../MenuMobile/MenuMobile'
import { PageNotFound } from '../../pages/PageNotFound/PageNotFound'
import { PrivacyPolicy } from '../../pages/PrivacyPolicy/PrivacyPolicy'
import { Footer } from '../Footer/Footer'
import { Contacts } from '../../pages/Contacts/Contacts'
import { Company } from '../../pages/Company/Company'
import { Licensing } from '../../pages/Licensing/Licensing'
import { SanitaryHygienic } from '../../pages/SanitaryHygienic/SanitaryHygienic'
import { InformationSystem } from '../../pages/InformationSystem/InformationSystem'
import { ModalImage } from '../ModalImage/ModalImage'
import { BigForm } from '../BigForm/BigForm'
import { Feedback } from '../../pages/Feedback/Feedback'
import { ModalTime } from '../ModalTime/ModalTime'
import Blog from '../../pages/Blog/Blog'
import { OphthalmologOffice } from '../../pages/OphthalmologOffice/OphthalmologOffice'
import { UrologicalOffice } from '../../pages/UrologicalOffice/UrologicalOffice'
import { FunctionalDiagnostics } from '../../pages/FunctionalDiagnostics/FunctionalDiagnostics'
import { CardiologistOffice } from '../../pages/CardiologistOffice/CardiologistOffice'
import { ClinicalDiagnosticLaboratory } from '../../pages/ClinicalDiagnosticLaboratory/ClinicalDiagnosticLaboratory' 
import { StamologicalLaboratory } from '../../pages/StamologicalLaboratory/StamologicalLaboratory'


export const App = () =>{


  const [isPreloader, setIsPreloader] = useState(true)

useEffect(() => {

    setTimeout(function() {
        setIsPreloader(false);
}, 3000);
}, []);

  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)
  const [isBigFormOpen, setIsBigFormOpen] = useState(false)
  const [isModalTime, setIsModalTime] = useState(false)

  const [isIndex, setIsIndex] = useState(null)
  const [isArr, setIsArr] = useState(null)

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

useEffect(() => {

  setTimeout(function() {
    setIsModalTime(true);
  }, 15000);
}, []);

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleBigFormOpen = useCallback(() => {
    setIsBigFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
    setIsBigFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback((index, arr) => {
      setIsIndex(index)
      setIsArr(arr)
      setIsPopupImageOpen(true)
  }, [])

  const handleModalTimeClose = useCallback(() => {
    setIsModalTime(false)
  }, []);

  return (
    <div className={app.page}>
    <AppHeader
      isOpen={handleOpenMenuMobile}
    />
      <Routes> 
        <Route  path='/'  element={
          <>
          <Helmet>
            <title>Аренда медицинского оборудования, прокат для лицензирования по Москве и России</title>
            <meta name="description" content="Аренда медицинского оборудования. Медицинское оборудование для получение медицинской лицензии. Аренда мед. оборудования с доставкой по России!"></meta>
            <meta property="og:title" content="Аренда медицинского оборудования, прокат для лицензирования по Москве и России"/>
            <meta property="og:description" content="Аренда медицинского оборудования. Медицинское оборудование для получение медицинской лицензии. Аренда мед. оборудования с доставкой по России!"/>
          </Helmet>
          <Main
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
            isPreloader={isPreloader}
          />
          </>
        }/>
        <Route  path='/kontakty'  element={
          <>
          <Helmet>
            <title>Контакты</title>
            <meta name="description" content="Контакты"></meta>
            <meta property="og:title" content="Контакты"/>
            <meta property="og:description" content="Контакты"/>
          </Helmet>
          <Contacts
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
          </>
        }/>
        <Route  path='/prokat-oborudovaniya'  element={
          <>
          <Helmet>
            <title>Оборудование</title>
            <meta name="description" content="Прокат медицинского оборудования. Медицинское оборудование для получение медицинской лицензии. Аренда мед. оборудования с доставкой по России!"></meta>
            <meta property="og:title" content="Оборудование"/>
            <meta property="og:description" content="Прокат медицинского оборудования. Медицинское оборудование для получение медицинской лицензии. Аренда мед. оборудования с доставкой по России!"/>
          </Helmet>
          <Company
            isOpen={handleBigFormOpen}
            popupOpen={handleOpen}
          />
          </>
        }/>
        <Route  path='/licenzirovanie-deyatelnosti'  element={
          <>
          <Helmet>
            <title>Лицензирование медицинской деятельности в Москве и по России</title>
            <meta name="description" content="Лицензирование медицинской деятельности. Низкая цена на медицинское лицензирование, кратчайшие сроки. Для подробностей или консультации звоните нам либо пишите"></meta>
            <meta property="og:title" content="Лицензирование медицинской деятельности в Москве и по России"/>
            <meta property="og:description" content="Лицензирование медицинской деятельности. Низкая цена на медицинское лицензирование, кратчайшие сроки. Для подробностей или консультации звоните нам либо пишите"/>
          </Helmet>
          <Licensing
            isOpen={handleBigFormOpen}
            popupOpen={handleOpen}
            />
          </>
        }/>
        <Route  path='/sanitarno-epidemiologicheskoe-zaklyuchenie'  element={
          <>
          <Helmet>
            <title> Получение санитарно-эпидемиологического заключения (СЭЗ)</title>
            <meta name="description" content="Выдача санитарно-эпидемиологических заключений. Получить санитарно-эпидемиологическое заключение вы можете с помощью нас. Подробнее на сайте."></meta>
            <meta property="og:title" content="Получение санитарно-эпидемиологического заключения (СЭЗ)"/>
            <meta property="og:description" content="Выдача санитарно-эпидемиологических заключений. Получить санитарно-эпидемиологическое заключение вы можете с помощью нас. Подробнее на сайте."/>
          </Helmet>
          <SanitaryHygienic
            isOpen={handleBigFormOpen}
            popupOpen={handleOpen}
          />
          </>
        }/>
        <Route  path='/edinaya-gosudarstvennaya-informacionnaya-sistema'  element={
          <>
          <Helmet>
          <title>Подключение медицинских организаций к ЕГИСЗ</title>
          <meta name="description" content="Подключение медицинских организаций к ЕГИСЗ. Подключиться к ЕГИСЗ вы можете с помощью нас."></meta>
          <meta property="og:title" content="Подключение медицинских организаций к ЕГИСЗ"/>
          <meta property="og:description" content="Подключение медицинских организаций к ЕГИСЗ. Подключиться к ЕГИСЗ вы можете с помощью нас."/>
          </Helmet>
          <InformationSystem
            isOpen={handleBigFormOpen}
            popupOpen={handleOpen}
          />
          </>
        }/>
        <Route  path='/otzyvy'  element={
          <>
          <Helmet>
            <title>Отзывы</title>
            <meta name="description" content="Отзывы"></meta>
            <meta property="og:title" content="Отзывы"/>
            <meta property="og:description" content="Отзывы"/>
        </Helmet>
          <Feedback
            isImageOpen={handleImageOpen}
            popupOpen={handleOpen}
          />
        </>  
        }/>
        <Route  path='/blog'  element={
          <>
          <Helmet>
            <title>Блог</title>
            <meta name="description" content="Блог"></meta>
            <meta property="og:title" content="Блог"/>
            <meta property="og:description" content="Блог"/>
          </Helmet>
          <Blog
            isImageOpen={handleImageOpen}
            popupOpen={handleOpen}
          />
          </>
        }/>
        <Route  path='/kabinet-oftalmologa'  element={
          <>
          <Helmet>
            <title>Стандарт оснащения офтальмолога, оборудование для лицензирования офтальмологического кабинета</title>
            <meta name="description" content="Стандарт оснащения кабинета офтальмологического кабинета. Нужное оборудование для лицензирования, нюансы и порядок действий. Подробную информацию вы найдете на странице."></meta>
            <meta property="og:title" content="Стандарт оснащения офтальмолога, оборудование для лицензирования офтальмологического кабинета"/>
            <meta property="og:description" content="Стандарт оснащения кабинета офтальмологического кабинета. Нужное оборудование для лицензирования, нюансы и порядок действий. Подробную информацию вы найдете на странице."/>
          </Helmet>
          <OphthalmologOffice
          />
          </>
        }/>
        <Route  path='/kabinet-urologa'  element={
          <UrologicalOffice
          />
        }/>
        <Route  path='/kabinet-funkcionalnoy-diagnostiki'  element={
          <FunctionalDiagnostics
          />
        }/>
        <Route  path='/kabinet-kardiologii'  element={
          <CardiologistOffice
          />
        }/>
        <Route  path='/kliniko-diagnosticheskaya-laboratoriya'  element={
          <ClinicalDiagnosticLaboratory
          />
        }/>
        <Route  path='/privacy-policy'  element={
          <PrivacyPolicy/>
        }/>
        <Route  path='/kabinet-stomatologii'  element={
          <StamologicalLaboratory/>
        }/> 
        <Route path='*' element={
          <PageNotFound/>
                  }/>
      </Routes>
      <Footer/>
      {isMenuMobile && <MenuMobile
        onClose={handleCloseMenuMobile}
      />}
      {isPopupFormOpen && <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isModalTime && <ModalTime
        onClose={handleModalTimeClose}
        popupOpen={handleOpen}
      ></ModalTime>}
      {isBigFormOpen && <BigForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></BigForm>}
      {isPopupOpen && <Modal
        onClose={handleClose}
      ></Modal>}
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
        index={isIndex}
        arr={isArr}
      />}
      <UpButton/>
      <ButtonCommunication
        isOpen={handleFormOpen}
      />
    </div>
  )
}