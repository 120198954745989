import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MenuMobile.module.scss';
import logo from '../../image/Header/logoti.png'

export const MenuMobile = ({onClose}) => {

    return (
        <div className={styles.main}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                    <Link className={styles.menu_link} to='/' onClick={onClose}>Главная</Link>
                    <Link className={styles.menu_link} to="/prokat-oborudovaniya" onClick={onClose}>Прокат оборудования</Link>
                    <Link className={styles.menu_link} to="/licenzirovanie-deyatelnosti" onClick={onClose}>Лицензирование</Link>
                    <Link className={styles.menu_link} to="/sanitarno-epidemiologicheskoe-zaklyuchenie" onClick={onClose}>СЭЗ</Link>
                    <Link className={styles.menu_link} to="/edinaya-gosudarstvennaya-informacionnaya-sistema" onClick={onClose}>ЕГИСЗ</Link>
                    <Link className={styles.menu_link} to="/otzyvy" onClick={onClose}>Отзывы</Link>
                    <Link className={styles.menu_link} to="/blog" onClick={onClose}>Блог</Link>
                    <Link className={styles.menu_link} to='/kontakty' onClick={onClose}>Контакты</Link>
                </nav>
        </div>
    );
}
