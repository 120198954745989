import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './OphthalmologOffice.module.scss'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import image from '../../image/OphthalmologOffice/img_1.webp'
import image_1 from '../../image/OphthalmologOffice/img_2.webp'
import image_2 from '../../image/OphthalmologOffice/img_3.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const OphthalmologOffice = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'Кабинет офтальмолога'}
            >
                <p className={styles.menu_text}><span>&ensp;/&ensp;</span></p>
                <Link className={styles.menu_link} to='/blog'>Блог</Link>
            </BreadCrumbs>
            <SliderTechnologies/>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}} 
            >
                    <motion.img className={styles.image} src={image} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h1 className={styles.title}>Оснащение <span>кабинета&#160;офтальмолога</span></h1>
                        <p className={styles.text}>&#8195;Основной задачей офтальмолога является диагностика и своевременное назначение эффективного лечения патологий и заболеваний глаз. Стандарт оснащения офтальмологии для лицензирования в 2023 регламентируется законодательством РФ.</p>
                        <p className={styles.text}>Нормативными документами, которые контролируют работу офтальмологических кабинетов, являются: приказ МЗО №115н от 27.02.2010; закон №99-ФЗ от 04.05.2011; приказ МЗО №910н от 13.11.2012; приказ МЗО №786н от 31.07.2020; СанПиН 2.1.3.2630-10; постановление №291 от 16.04.2012; приказ МЗО №442н от 25.10.2012; постановление №58 от 18.05.2010; приказ МЗО №902н от 12.11.2012; СанПиН 2.1.3678-20.</p>
                        <p className={styles.text}>Открытие медицинского кабинета возможно только после получения лицензии. Для получения такого разрешения следует учесть все требования, касающиеся оснащения кабинета офтальмологии.</p>
                        <h2 className={styles.subtitle}>Общие требования</h2>
                        <p className={styles.text}>&#8195;Нормативные требования абсолютно одинаковы для проведения процесса лицензирования частного или кабинета в государственной поликлинике. Стандарты оснащения устанавливают общие требования к следующим параметрам:</p>                        
                        <p className={styles.text} ><span>&#10003;</span> Расположение. Помещение для оказания медицинских услуг в области офтальмологии следует размещать в районах с нормальным уровнем ПДК опасных соединений.</p>                       
                        <p className={styles.text} ><span>&#10003;</span> Площадь. Размер комнаты должен позволять эргономично разместить предметы мебели, необходимое оборудование, инструменты.</p>                      
                        <p className={styles.text} ><span>&#10003;</span> Наличие изолированных пространств. Обособленные помещения предназначены для приема пациентов с инфекционными болезными глаз.</p>                       
                        <p className={styles.text} ><span>&#10003;</span> Освещение. Лампы, электрические приборы должны строго соответствовать строительным нормам.</p>                      
                        <p className={styles.text} ><span>&#10003;</span> Внутренняя отделка. Допустимо использование исключительно безопасных материалов с высокими показателями износостойкости.</p>                        
                        <p className={styles.text} ><span>&#10003;</span> Коммуникации. Монтаж вентиляции, отопления, водоснабжения проводится в соответствии со СниПами.</p>                       
                        <p className={styles.text} ><span>&#10003;</span> Санузлы. Туалетные помещения, раздевалки обустраивать отдельно для медперсонала и посетителей.</p>                       
                        <p className={styles.text} ><span>&#10003;</span> Мебель. Предметы интерьера и мебели должны иметь гладкую поверхность.</p>                      
                        <h2 className={styles.subtitle}>Расположение</h2>
                        <p className={styles.text}>&#8195;Кабинет врача-офтальмолога можно располагать в жилом многоквартирном доме или отдельно стоящем. Основным требованием является отдельный вход для посетителей. Согласно стандартов оснащения, кабинет должен находится не ниже 1 метра от уровня главного входа.</p>
                        <h2 className={styles.subtitle}>Необходимая площадь</h2>
                        <p className={styles.text}>&#8195;При планировании кабинета следует предусмотреть деление помещения на 2 комнаты. Темная зона может занимать минимум 4 м2, светлая — 18 м2. Длина светлой комнаты должна составлять не меньше 5 метров. Такое расстояние считается оптимальным для проверки уровня зрения больного. Высота потолка в помещении — от 2,6 м.</p>
                        <p className={styles.text}>Темная комната предназначена для проведения более детального обследования. Если нет возможности выделить отдельное помещение для темной зоны, тогда зонирование осуществляют с помощью специальных штор, жалюзи, ширм.</p>
                        <p className={styles.text}>Операционные блоки и пункт неотложной медпомощи должны быть поделены на «чистые» (для здоровых пациентов) и «гнойные» (для инфекционных больных) зоны.</p>
                        <p className={styles.text}>Площадь помещения рассчитывают, опираясь на предполагаемое число посетителей. Размер комнаты ожидания не должен быть меньше 1,2 м2 для одного больного. Наркозная (для операционных блоков) и стерилизационная комнаты должны быть площадь более 8м2.</p>
                        <h2 className={styles.subtitle}>Отделка внутренних поверхностей</h2>
                        <p className={styles.text}>&#8195;Стены, пол, потолок должны иметь абсолютно гладкое покрытие. Для отделки допустимо использовать материалы с высокой влаго- и износостойкостью. Только в таком случае можно достичь требуемого качества чистоты помещения и внутренних поверхностей.</p>
                        <p className={styles.text}>В медкабинете регулярно проводятся влажные уборки, поэтому рекомендуется все поверхности облицовывать водостойкими материалами. Потолок и стены можно покрыть матовой масляной краской неярких оттенков, чтобы избежать появления солнечных бликов во время проверки зрения. Использование бумажных обоев — нарушение требований противопожарной безопасности.</p>
                        <p className={styles.text}>В помещении устанавливается раковина с подведением холодной и горячей воды. Размещают ее на высоте 1,5 м от пола, окружают водостойкими материалами на 20 см в каждую сторону.</p>
                        <p className={styles.text}>Стыки должны быть герметичны, углы заокруглены. Это поможет избежать накопления пыли, грязи, патогенных бактерий и предупредить развитие микробной контаминации помещения.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_1} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Освещение <span>кабинета&#160;офтальмолога</span></h2>
                        <p className={styles.text}>&#8195;Оснащение отделения офтальмологии предусматривает естественное освещение и размещение источников искусственного. Рекомендуется размещать помещения для офтальмологов в северной части здания. Тогда естественного освещения будет достаточно для оказания квалифицированной помощи в любое время года. Если окна помещения выходят на юг, их следует обеспечить специальными средствами защиты, включая козырьки и жалюзи.</p>
                        <p className={styles.text}>Светильники оснащаются сплошными рассеивателями. В качестве источников искусственного света могут использоваться настольные, настенные, переносные лампы. Общий уровень светового потока должен быть не менее 300 люмен на 1 м2.</p>
                        <h2 className={styles.subtitle}>Условия микроклимата</h2>
                        <p className={styles.text}>&#8195;Для создания комфортных условий в офтальмологическом кабинете устанавливаются системы вентиляции, отопления. Коммуникации должны быть скрыты, но при этом доступны в случае необходимости проведения ремонта.</p>
                        <p className={styles.text}>В помещении должна быть температура не ниже 18 градусов, влажность — около 55%. В медицинском учреждении должна быть обособленная система нагрева и очистки воздуха, соответствующая требованиям СЭС. В таком случае удастся соблюсти норматив по числу микроорганизмов на 1 м3 воздушного пространства.</p>
                        <p className={styles.text}>Помещение следует регулярно проветривать, обрабатывать посредством бактерицидных ламп. Таким образом удается избежать контаминации кабинета болезнетворными бактериями, распространения вирусных заболеваний.</p>
                    </motion.div>
            </motion.div>
            <motion.div className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}} 
            >
                    <motion.img className={styles.image} src={image_2} alt='больница' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Перечень оборудования&#160;для <span> кабинета&#160;офтальмолога</span></h2>
                        <p className={styles.text}>&#8195;Порядок оснащения кабинета офтальмолога предполагает установку медицинского оборудования, которое должно иметь лицензию. Предметы мебели, необходимая аппаратура размещаются в помещении так, чтобы врач сидел лицом к пациенту. Лицо пациента должно находиться под источником света.</p>
                        <p className={styles.text}>В кабинете офтальмолога должно быть установлено:</p>
                        <p className={styles.text} ><span>&#10003;</span> диафаноскоп — прибор для исследования тканей на предмет пропускания света;</p>
                        <p className={styles.text} ><span>&#10003;</span> экзофтальмометр — прибор для измерения степени экзофтальма;</p>
                        <p className={styles.text} ><span>&#10003;</span> налобный бинокулярный офтальмоскоп для изучения глазного яблока;</p>
                        <p className={styles.text} ><span>&#10003;</span> гониоскоп — аппарат для изучения передней глазной камеры;</p>
                        <p className={styles.text} ><span>&#10003;</span> 2 рабочих стола (для врача и медсестры);</p>
                        <p className={styles.text} ><span>&#10003;</span> 2 рабочих стула;</p>
                        <p className={styles.text} ><span>&#10003;</span> набор линз для проведения офтальмоскопии;</p>
                        <p className={styles.text} ><span>&#10003;</span> комплект магнитов;</p>
                        <p className={styles.text} ><span>&#10003;</span> 2 стула для пациента;</p>
                        <p className={styles.text} ><span>&#10003;</span> автопроектор символов;</p>
                        <p className={styles.text} ><span>&#10003;</span> набор линеек с линзами для проведения скиаскопии;</p>
                        <p className={styles.text} ><span>&#10003;</span> электроофтальмоскоп;</p>
                        <p className={styles.text} ><span>&#10003;</span> аппарат для измерения внутриглазного давления;</p>
                        <p className={styles.text} ><span>&#10003;</span> тонометр Маклакова;</p>
                        <p className={styles.text} ><span>&#10003;</span> линза Гольдмана;</p>
                        <p className={styles.text} ><span>&#10003;</span> прибор для исследования поля зрения;</p>
                        <p className={styles.text} ><span>&#10003;</span> фонарик офтальмолога;</p>
                        <p className={styles.text} ><span>&#10003;</span> набор таблиц для определения цветоощущения;</p>
                        <p className={styles.text} ><span>&#10003;</span> набор пробных линз для определения диоптрий;</p>
                        <p className={styles.text} ><span>&#10003;</span> авторефрактометр для изучения показателя преломления;</p>
                        <p className={styles.text} ><span>&#10003;</span> прибор для изучения видимых частей глаза;</p>
                        <p className={styles.text} ><span>&#10003;</span> 2 комплекта для промывания слезных желез;</p>
                        <p className={styles.text} ><span>&#10003;</span> 2 расширителя для век;</p>
                        <p className={styles.text} ><span>&#10003;</span> 2 векоподъемника;</p>
                        <p className={styles.text} ><span>&#10003;</span> шкафы для хранения лекарственных средств, документации и перевязочных материалов, столик манипуляционный/инструментальный, пинцеты, копье для удаления инородного тела, набор для теста Ширмера, скальпель, ножницы предоставляются по требованию.</p>
                        <p className={styles.text} >Открытие кабинета офтальмолога также подразумевает обустройство комнаты ожидания для посетителей, помещений для работы врача и медперсонала, переодевания и отдыха.</p>     
                    </motion.div>
            </motion.div>
            <SliderTechnologies/>
        </motion.section>
    )
}